import React from 'react'
import { graphql, Link } from 'gatsby'
import Helmet from 'react-helmet'
import config from '../utils/siteConfig'
import Layout from '../components/Layout'
import Container from '../components/Container'
import PageBody from '../components/PageBody'
import TagList from '../components/TagList'
import PostLinks from '../components/PostLinks'
import PostDate from '../components/PostDate'
import PageTitle from '../components/PageTitle'
import SEO from '../components/SEO'
import styled from 'styled-components'
import Img from 'gatsby-image'

const ImageList = styled.ul`
  width: 100%;
  margin: 0 auto 1em auto;
`

const ImageItem = styled.li`
  display: inline-block;
  margin: 0 0.25em 0.25em 0;
  width: 100%;
`

const PostTemplate = ({ data, pageContext }) => {
  console.log(data)

  const { title, slug, body, publishDate, tags } = data.contentfulPost
  const postNode = data.contentfulPost
  const previous = pageContext.prev
  const next = pageContext.next

  return (
    <Layout>
      <Helmet>
        <title>{`${title} - ${config.siteTitle}`}</title>
      </Helmet>
      <SEO pagePath={slug} postNode={postNode} postSEO />

      <Container>
        <PageTitle>{title}</PageTitle>
        <PostDate date={publishDate} />
        <PageBody body={body} />

        <ImageList>
          {data.contentfulPost.imageContent.map(image => (
            <ImageItem key={image.id}>
              <a title={image.title} href={image.fluid.srcWebp}>
                <Img alt={image.title} fluid={image.fluid} />
              </a>
            </ImageItem>
          ))}
        </ImageList>

        {tags && <TagList tags={tags} />}

        <PostLinks previous={previous} next={next} />
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    contentfulPost(slug: { eq: $slug }) {
      title
      slug
      metaDescription {
        internal {
          content
        }
      }
      publishDate(formatString: "MMMM DD, YYYY")
      publishDateISO: publishDate(formatString: "YYYY-MM-DD")
      tags {
        title
        id
        slug
      }
      heroImage {
        title
        fluid(maxWidth: 1280) {
          ...GatsbyContentfulFluid_tracedSVG
        }
        ogimg: resize(width: 1200) {
          src
          width
          height
        }
      }
      imageContent {
        id
        title
        fluid(maxWidth: 1280) {
          srcWebp
          ...GatsbyContentfulFluid_tracedSVG
        }
      }
      body {
        childMarkdownRemark {
          html
          excerpt(pruneLength: 320)
        }
      }
    }
  }
`

export default PostTemplate
